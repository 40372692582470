import React, { useState } from "react"
import "../../theme/type.scss"
import CustomFooter from "../../components/CustomFooter"
import GdprCookies from "../../components/GdprCookies"
import HeaderLayout from "../../components/layout/HeaderLayout"
import "../../theme/layout.scss"
import "../../theme/david.scss"
import { HeadApi } from "../../components/HeadApi"
import ModalSuscribe from "../../components/layout/ModalSuscribre"

export const Head = ({ data }) => {

  return (
    <HeadApi
      title="Ranking de rendimientos potenciales"
      description="Ranking de rendimientos potenciales"
    />
  )
}


const SemanalRankingRendimientos = ({ data }) => {
  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }


  return (
    <>
      <HeaderLayout mb={true} suscribe={true} openModal={openSuscribe} />
      <div className="wrap-content">
        <div className="header--section">
          <h2 className="title--section">Ranking de rendimientos potenciales</h2>
        </div>

        <div>

          <iframe className="airtable-embed"
                  src="https://airtable.com/embed/appTIIVoNxZwESogt/shrmZr4kunhpsGeZc?viewControls=on"
                  width="100%" height="533"
                  style={{ background: "transparent", border: "1px solid #ccc" }}></iframe>
          <p>(m) Consenso de Mercado <br /> (*) Estimado para cierre 2025. <br />(R) En revisión. <br />(n.a.) No
            aplica.</p>
        </div>
      </div>
      <CustomFooter openModal={openSuscribe} />
      <GdprCookies />
      {
        open && <ModalSuscribe closeModal={closeSuscribe} />
      }
    </>
  )
}

export default SemanalRankingRendimientos
